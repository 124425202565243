<script>
export default {
	data() {
		return {
			year: (new Date()).getFullYear()
		}
	},
	methods: {
		getLanguage() {
			var l;
			switch (this.$i18n.locale) {
				case 'de':
					l = "Deutschland (Deutsch)";
					break;
				case 'en':
					l = "United States (English)";
					break;
				case 'es':
					l = "España (Español)";
					break;
				case 'fr':
					l = "France (Français)";
					break;
				case 'gr':
					l = "Ελλάδα (ελληνικά)";
					break;
				case 'tr':
					l = "Türkiye (Türkçe)";
					break;
				default:
					l = "-";
					break;
			}
			return l;
		}
	}
}
</script>
<template>
	<!-- BEGIN #footer -->
	<div id="footer" class="app-footer py-3 bg-gray-800 text-body text-opacity-75" data-bs-theme="dark">
		<div class="container-xxl px-3 px-lg-5">
			<div class="row">
				<div class="col-sm-4 text-sm-start d-none d-lg-block">
					<a href="https://unisuite.de/legal-notice" class="text-decoration-none text-body text-opacity-50 me-4">{{
						$t("APP.FOOTER.LEGALMENU.LEGALNOTICE") }}</a>
					<a href="https://unisuite.de/privacy-policy" class="text-decoration-none text-body text-opacity-50 me-4">{{
						$t("APP.FOOTER.LEGALMENU.PRIVACYPOLICY") }}</a>
					<a href="https://unisuite.de/tos" class="text-decoration-none text-body text-opacity-50 me-4">{{
						$t("APP.FOOTER.LEGALMENU.TERMSOFSERVICE") }}
					</a>
				</div>
				<div class="col-sm-4 mb-2 text-sm-start d-lg-none">
					<div class="dropdown me-4 d-inline">
						<a href="#" class="text-decoration-none dropdown-toggle text-body text-opacity-50"
							data-bs-toggle="dropdown"><i class="fa-solid fa-scale-balanced"></i> {{
								$t("APP.FOOTER.LEGALMENU.TITLE") }}</a>
						<ul class="dropdown-menu">
							<li>
								<a href="https://unisuite.de/legal-notice" class="dropdown-item">{{
									$t("APP.FOOTER.LEGALMENU.LEGALNOTICE") }}</a>
							</li>
							<li>
								<a href="https://unisuite.de/privacy-policy" class="dropdown-item">{{
									$t("APP.FOOTER.LEGALMENU.PRIVACYPOLICY") }}</a>
							</li>
							<li>
								<a href="https://unisuite.detos" class="dropdown-item">{{
									$t("APP.FOOTER.LEGALMENU.TERMSOFSERVICE") }}</a>
							</li>
						</ul>
					</div>
				</div>
				<div class="col-sm-4 mb-2 text-sm-center">
					<div class="footer-copyright-text">Copyright &copy; {{ year }} <RouterLink to="/"
							class="text-decoration-none">Key2Host</RouterLink> - {{ $t("APP.LIC") }}.</div>
				</div>
				<div class="col-sm-4 text-sm-end">
					<RouterLink to="/choose-lang" class="text-decoration-none text-body text-opacity-50">
						<i class="fa-solid fa-globe"></i> {{ getLanguage() }}
					</RouterLink>
				</div>
			</div>
		</div>
	</div>
	<!-- END #footer -->
</template>