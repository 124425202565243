import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex justify-content-center align-items-center"
}
const _hoisted_2 = {
  class: "position-fixed top-0 start-0 w-100 h-100 bg-black opacity-90 d-flex flex-column align-items-center justify-content-center",
  style: {"z-index":"9999"}
}
const _hoisted_3 = { class: "text-white" }
const _hoisted_4 = {
  key: 1,
  class: "d-flex justify-content-center align-items-center"
}
const _hoisted_5 = {
  class: "position-fixed top-0 start-0 w-100 h-100 bg-black opacity-90 d-flex flex-column align-items-center justify-content-center",
  style: {"z-index":"9999"}
}
const _hoisted_6 = { class: "text-white" }
const _hoisted_7 = { class: "text-white" }
const _hoisted_8 = { class: "text-white text-opacity-25" }
const _hoisted_9 = { class: "fixed-bottom mb-3 text-center" }
const _hoisted_10 = {
  href: "https://www.key2host.com",
  class: "btn btn-outline-theme fw-bold rounded-pill px-3 py-2"
}

import { ref, onMounted } from 'vue';
import { RouterView } from 'vue-router';
import { useAppOptionStore } from '@/apps/status/stores/app-option';
import { useAppVariableStore, generateVariables } from '@/shared/stores/app-variable';
import { ProgressFinisher, useProgress } from '@marcoschulte/vue3-progress';
import AppHeader from '@/apps/status/components/Header.vue';
import AppFooter from '@/apps/status/components/Footer.vue';
import router from './router';
import axios from "axios";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const appVariable = useAppVariableStore();
const appOption = useAppOptionStore();
const progresses = [] as ProgressFinisher[];
var isLoading = ref(true);
var hasData = ref(false);

function changeColorSheme(newColorScheme) {
	appOption.appMode = newColorScheme;
	document.documentElement.setAttribute('data-bs-theme', newColorScheme);
	var newVariables = generateVariables();
	appVariable.font = newVariables.font;
	appVariable.color = newVariables.color;
}

window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
	const newColorScheme = event.matches ? "dark" : "light";
	changeColorSheme(newColorScheme);
});

if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
	changeColorSheme("dark");
}

onMounted(() => {
	const fetchData = async () => {
		try {
			const res = await axios.get('https://saci.key2host.com/public/status/');
			if (res.data && Object.keys(res.data).length > 0) {
				appOption.appData = res.data;
				hasData.value = true;
				console.log('[K2H API] Data fetched successfully');
			} else {
				console.log('[K2H API] No data fetched: Empty response');
			}
		} catch (error) {
			appOption.appData = null;
			hasData.value = false;
			console.log('[K2H API] Error fetching data:', error);
		}
	};

	fetchData();
	setInterval(fetchData, 10000);
});

router.beforeEach(async (to, from) => {
	isLoading.value = true;
	progresses.push(useProgress().start());
	if (to.params.id && to.path.startsWith('/stats/')) {
		const id = to.params.id;
		const appDataItem = appOption.appData[id];
		if (appDataItem == null) {
			router.replace("/404");
		}
	}
})

router.afterEach(async (to, from) => {
	isLoading.value = false;
	document.body.scrollTop = 0;
	document.documentElement.scrollTop = 0;
	progresses.pop()?.finish();
})

document.querySelector('body').classList.add('app-init');

return (_ctx: any,_cache: any) => {
  const _component_vue3_progress_bar = _resolveComponent("vue3-progress-bar")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["app", {
		'app-header-menu-search-toggled': _unref(appOption).appHeaderSearchToggled,
		'app-content-full-height': _unref(appOption).appContentFullHeight,
		'app-content-full-width': true,
		'app-without-header': _unref(appOption).appHeaderHide,
		'app-boxed-layout': _unref(appOption).appBoxedLayout,
		'app-footer-fixed': _unref(appOption).appFooterFixed,
	}])
  }, [
    _createVNode(_component_vue3_progress_bar),
    (_unref(isLoading))
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _cache[0] || (_cache[0] = _createElementVNode("div", {
              class: "spinner-border mb-2 text-white",
              style: {"width":"3rem","height":"3rem"},
              role: "status"
            }, [
              _createElementVNode("span", { class: "visually-hidden" }, "Loading...")
            ], -1)),
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t("APP.OTHER.WAIT")) + "...", 1)
          ])
        ]))
      : _createCommentVNode("", true),
    (!_unref(hasData) && !_unref(isLoading))
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[2] || (_cache[2] = _createElementVNode("div", { class: "mb-2" }, [
              _createElementVNode("i", {
                class: "fa-solid fa-exclamation fa-2xl fa-beat-fade text-white",
                style: {"--fa-animation-duration":"4s"}
              })
            ], -1)),
            _createElementVNode("h2", null, _toDisplayString(_ctx.$t("APP.ERRORS.NODATA.TITLE")), 1),
            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t("APP.ERRORS.NODATA.DESC.1")), 1),
            _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t("APP.ERRORS.NODATA.DESC.2")), 1),
            _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
            _createElementVNode("small", _hoisted_8, _toDisplayString(_ctx.$t("APP.ERRORS.NODATA.TRYNOTICE")), 1),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("a", _hoisted_10, [
                _createTextVNode(_toDisplayString(_ctx.$t("APP.ERRORS.NODATA.BACK")) + " ", 1),
                _cache[1] || (_cache[1] = _createElementVNode("i", { class: "fa fa-arrow-right ms-2 opacity-5" }, null, -1))
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (!_unref(appOption).appHeaderHide)
      ? (_openBlock(), _createBlock(AppHeader, { key: 2 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["app-content", _unref(appOption).appContentClass])
    }, [
      (_unref(hasData))
        ? (_openBlock(), _createBlock(_unref(RouterView), { key: 0 }))
        : _createCommentVNode("", true)
    ], 2),
    (_unref(appOption).appFooter && _unref(hasData))
      ? (_openBlock(), _createBlock(AppFooter, { key: 3 }))
      : _createCommentVNode("", true)
  ], 2))
}
}

})