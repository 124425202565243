import { createRouter, createWebHistory } from "vue-router";
import Cookies from "js-cookie";
import i18n from '@/apps/status/main';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    // DASHBOARD
    { path: '/', meta: { title: 'Status Übersicht', visible: true }, component: () => import('@/apps/status/views/Home.vue') },
    { path: '/stats/:id', meta: { title: 'Status Details' }, component: () => import('@/apps/status/views/Stats.vue') },
    // UTILS
    { path: '/choose-lang', meta: { title: 'Language' }, component: () => import('@/apps/status/views/utils/ChooseLang.vue') },
    // 404
    { path: '/:pathMatch(.*)*', meta: { title: 'Error 404' }, component: () => import('@/apps/status/views/utils/PageError.vue') },
  ],
  scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
		  return savedPosition;
		}
		return { top: 0 };
	  },
});

router.beforeEach((to, from, next) => {
  // Inject meta information
  const title: string = (to.meta.title as string) || 'ComingSoon';
  const description: string = (to.meta.description as string) || 'ComingSoon';
  const visible: boolean = to.meta.visible == false;

  document.title = title + ' | Key2Host';

  const descriptionElement = document.querySelector('head meta[name="description"]');
  if (descriptionElement) {
    descriptionElement.setAttribute('content', description);
  }

  let robotsElement = document.querySelector('head meta[name="robots"]');
  if (!robotsElement) {
    robotsElement = document.createElement('meta');
    robotsElement.setAttribute('name', 'robots');
    document.head.appendChild(robotsElement);
  }
  robotsElement.setAttribute('content', visible ? 'index, follow' : 'noindex, nofollow');

  // Language check and other logic...
  const langC = Cookies.get('uslang');
  if (!langC && to.path !== '/choose-lang') {
    next();
  } else {
    if (langC) {
      i18n.global.locale.value = langC as 'de' | 'en' | 'es' | 'fr' | 'gr' | 'tr';
    }
  }

  next();
});

export default router;