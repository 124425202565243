import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "header",
  class: "app-header navbar navbar-expand-lg p-0"
}
const _hoisted_2 = { class: "container-xxl px-3 px-lg-5" }
const _hoisted_3 = { class: "brand" }
const _hoisted_4 = { class: "fs-20px d-none d-lg-block ms-2" }
const _hoisted_5 = { class: "ms-2" }
const _hoisted_6 = {
  href: "https://www.key2host.com",
  class: "btn btn-theme fw-bold rounded-pill px-3 py-2"
}

import { useRouter } from 'vue-router'


export default /*@__PURE__*/_defineComponent({
  __name: 'Header',
  setup(__props) {

function isActiveRoute(path: string) {
	const currentRoute = useRouter().currentRoute.value.path;
	var match = false;
	if (currentRoute == path || currentRoute + '/' == path) {
		match = true;
	}
	return match;
}

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_RouterLink, {
          class: "container brand-bg",
          to: "/"
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createElementVNode("img", {
              src: "/assets/img/brand/logo-wide.svg",
              alt: "Key2Host Logo"
            }, null, -1)
          ])),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("APP.HEADER.TITLE")), 1),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("a", _hoisted_6, [
          _createTextVNode(_toDisplayString(_ctx.$t("APP.HEADER.BACK")) + " ", 1),
          _cache[1] || (_cache[1] = _createElementVNode("i", { class: "fa fa-arrow-right ms-2 opacity-5" }, null, -1))
        ])
      ])
    ])
  ]))
}
}

})